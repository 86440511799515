import styled from '@emotion/styled'
import { IThemeValue } from 'components/sections/section.model'
import { mediaBreakpoint, BreakPoints } from '../../settings/breakpoints'

export interface IHeadingProps {
    sectionTheme?: IThemeValue;
}

export const getColorStyles = (theme?: IThemeValue) => {
	switch (theme) {
	case 'white-text':
		return '#fff'
	case 'default':
	default:
		return '#121619'
	}
}

export const getSectionThemeColor = (props: IHeadingProps) => {
	if (!props) {
		return 'inherit'
	}

	return getColorStyles(props.sectionTheme)
}

export const H2 = styled.h2`
    font-size: 3.4rem;
    line-height: 3.9rem;
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    margin: 0 0 16px;
    font-weight: 800;
    br {
        display: none;
    }
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 6rem;
        line-height: 6.4rem;
        br {
            display: initial;
        }
    }
`
export const SpanH2 = styled.span`
    font-size: 3.4rem;
    line-height: 3.9rem;
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    margin: 0 0 16px;
    font-weight: 800;
    br {
        display: none;
    }
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 6rem;
        line-height: 6.4rem;
        br {
            display: initial;
        }
    }
`

export const H3 = styled.h3`
    font-size: 3rem;
    line-height: 3.6rem;
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    margin: 0 0 16px;
    font-weight: 800;
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 4.8rem;
        line-height: 5.2rem;
    }
`
export const SpanH3 = styled.span`
    font-size: 3rem;
    line-height: 3.6rem;
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    margin: 0 0 16px;
    font-weight: 800;
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 4.8rem;
        line-height: 5.2rem;
    }
`

export const H4 = styled.h4`
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 800;
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 3.6rem;
        line-height: 4.8rem;
    }
`

export const SpanH4 = styled.h4`
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 800;
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 3.6rem;
        line-height: 4.8rem;
    }
`

export const H5 = styled.h5`
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 800;
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 2rem;
        line-height: 2.8rem;
    }
`
export const SpanH5 = styled.span`
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 800;
    margin: 0 0 1.45rem; 
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 2rem;
        line-height: 2.8rem;
    }
`

export const H6 = styled.h6`
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 800;
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
`
export const SpanH6 = styled.span`
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 800;
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
`
export const P = styled.p`
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${(props: IHeadingProps) => getSectionThemeColor(props)};
    margin: 0 0 32px;
    ${ mediaBreakpoint( BreakPoints.SM ) } {
        font-size: 2rem;
        line-height: 2.8rem;
    }
`