import * as React from "react"
import { css } from "@emotion/react"
import getLinkPath from "utils/link"
import { ContentfulLinkReference } from "common/types"
import Link from "components/Link"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"

const linkStyles = css`
	font-size: 1.6rem;
	line-height: 2.4rem;
	text-decoration: none;
	color: #181818;
	margin: 0 0 8px;
	padding-right: 30px;
	&:hover {
		color: var(--main-color);
		text-decoration: underline;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`

export interface FooterLinkProps {
	id: string
	text: string
	link: ContentfulLinkReference
}

const FooterLink: React.FC<FooterLinkProps> = ({ text, link }) => {
	const path = React.useMemo(() => getLinkPath(link), [link])

	return (
		<Link css={linkStyles} to={path}>
			{text}
		</Link>
	)
}

export default React.memo(FooterLink)
