export const setPath = (path, width) => {
	const settingsPaths = path.split(/[? ]/)
	const widthParam = width ? `w=${width}&` : ''
	return [settingsPaths[0],  `?${widthParam}q=90&fm=webp `, settingsPaths[2]].join('')
}

const replacePaths = (path, width) => {
	return path.split(',').map(el => setPath(el, width)).join(',')
}

const resizeImageLinkPath = (imageObject, width) => {
	if(!imageObject?.image?.sources) {
		return imageObject
	}
	return {
		...imageObject,
		images: {
			...imageObject.images,
			sources: imageObject.images.sources.map(source => ({...source, type: "image/webp", srcSet: replacePaths(source.srcSet, width)})),
			...(imageObject.images.fallback ? {fallback: {
				...imageObject.images.fallback,
				src: replacePaths(imageObject.images.fallback.src, width),
				srcSet: replacePaths(imageObject.images.fallback.srcSet, width),
			}} : {})
		}
	}
}

export default resizeImageLinkPath


