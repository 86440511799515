import { ButtonProps } from "components/button"
import { MediaProps } from "components/Media"
import { ContentfulLink, ContentfulLinkReference } from "common/types"

export type IFeaturedTypes = "none" | "page" | "blog-post"

export interface IFeatured {
	id: string
	title?: {
		title: string
	}
	shortDescription?: string
	description?: {
		description: string
	}
	category?: string
	heroImage?: MediaProps
	referenceImage?: MediaProps
	slug?: string
}

export interface IOption {
	id: string
	label: string
}

// page with slug or url
export interface HeaderEntryLinkReference {
	__typename: string
	id: string
	slug?: string
	url?: string
	text: string
}

export interface HeaderEntryLink {
	__typename: string
	id: string
	text: string
	reference: ContentfulLinkReference
}

export interface HeaderEntry {
	__typename: string
	id: string
	toRight: boolean
	order: number
	link: HeaderEntryLink | ButtonProps
	flyoutLinks: ContentfulLink[] | null
	featuredBlogPost?: IFeatured
	featuredType?: IFeaturedTypes
	showCategory?: boolean
	itemsPerColumn?: number
	totalColumns?: number
	highlightedLinks?: ContentfulLink[] | null
}

export enum HeaderVariant {
	Default = "default",
	TransparentWhite = "transparent-white",
	TransparentBlack = "transparent-black",
}

export enum FooterVariant {
	Default = "default",
	DarkFooter = "dark-footer",
}
