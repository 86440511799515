import * as React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { ContentfulLink } from "common/types"
import Link from "components/Link"
import { theme } from "common/colorScheme"

const Title = styled.div`
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: 800;
	color: ${theme.color.black};
`

const containerStyles = css`
	display: flex;
	text-decoration: none;
	margin: 0 0 24px;
	&:nth-child(even) {
		order: 2;
	}
	&:nth-child(odd) {
		order: 1;
	}
	&:hover {
		${Title} {
			text-decoration: underline;
		}
	}
`

const MobileSubMenuEntry: React.FC<ContentfulLink> = ({ link, text }) => {
	const path = React.useMemo(() => {
		return link.slug ? `/${link.slug}/` : link.url
	}, [link])

	return (
		<Link css={containerStyles} to={path}>
			<div>
				<Title>{text}</Title>
			</div>
		</Link>
	)
}

export default React.memo(MobileSubMenuEntry)
