import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { GATSBY_HOST_PATH } from "settings/env"
import { useLinkContext } from "components/Link"
import * as process from "process"

export const query = graphql`
	fragment SEO on ContentfulSeo {
		__typename
		title
		description
		noIndex
		ogImage {
			fluid(maxWidth: 1200, maxHeight: 675, toFormat: JPG, quality: 90) {
				src
			}
			file {
				url
			}
		}
	}
	query {
		site {
			siteMetadata {
				title
				description
				image
			}
		}
	}
`

export interface SEOProps {
	title?: string
	description?: string
	ogImage?: any
	lang?: string
	noIndex?: boolean
}

const normalizeOGImagePath = (path: string) => {
	// eslint-disable-next-line no-nested-ternary
	return path.startsWith("//")
		? `https:${path}`
		: path.startsWith("/") && !path.startsWith("//")
			? `${GATSBY_HOST_PATH}${path}`
			: path
}

export const isBrowser = typeof window !== "undefined"
const knowledgeLatanaHostname = 'knowledge.latana.com'
const faqLatanaHostname = 'faq.latana.com'

const SEO = ({ title, description, noIndex, ogImage, lang = "en" }: SEOProps) => {
	const [cookiesAllowed, setCookiesAllowed] = useState(false)
	const { isKnowledgeCentre, isFaq } = useLinkContext()
	const { pathname, hostname } = useLocation()
	const { site } = useStaticQuery(query)
	const metaDescription = description || site.siteMetadata.description

	const image = React.useMemo(() => {
		const formatUrl = Boolean(ogImage?.file?.url)
		const ogSrc = ogImage?.fluid?.src ? ogImage?.fluid?.src : formatUrl && `${ogImage?.file?.url}?fm=jpg&w=1200&h=675`
		return normalizeOGImagePath(ogSrc || site.siteMetadata.image)
	}, [site, ogImage])

	const ogUrl = React.useMemo(() => {
		return `${GATSBY_HOST_PATH}${pathname}`
	}, [pathname])

	const cannonicalUrl = React.useMemo(() => {
		if (isKnowledgeCentre || isFaq || (hostname && !hostname.startsWith("www"))) {
			return null
		}

		if(process.env.KNOWLEDGE_ONLY) {
			return `https://${knowledgeLatanaHostname}${pathname}`
		}

		if(process.env.FAQ_ONLY) {
			return `https://${faqLatanaHostname}${pathname}`
		}

		return `https://latana.com${pathname}`
	}, [isKnowledgeCentre, hostname, pathname])

	useEffect(() => {
		if (isBrowser) {
			/* eslint-disable no-multi-assign */
			/* eslint-disable no-underscore-dangle */
			const _hsp = (window._hsp = window._hsp || [])

			_hsp.push([
				"addPrivacyConsentListener",
				(consent: { allowed: boolean }) => {
					if (consent.allowed) {
						setCookiesAllowed(true)
					} else {
						setCookiesAllowed(false)
					}
				},
			])
		}
	}, [isBrowser])

	return (
		<Helmet
			title={title}
			htmlAttributes={{
				lang,
			}}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: "facebook-domain-verification",
					content: "w8so24am925badu0kehzr4cnoj5n44"
				},
				{
					name: "description",
					content: metaDescription,
				},
				{
					property: "og:title",
					content: title,
				},
				{
					property: "og:description",
					content: metaDescription,
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:url",
					content: ogUrl,
				},
				{
					property: "og:image",
					content: image,
				},
				{
					name: "twitter:card",
					content: "summary_large_image",
				},
				{
					name: "twitter:title",
					content: title,
				},
				{
					name: "twitter:description",
					content: metaDescription,
				},
				{
					name: "twitter:image",
					content: image,
				},
			]}
		>
			{noIndex ? <meta name="robots" content="noindex" /> : null}
			{cannonicalUrl ? <link rel="canonical" href={cannonicalUrl} /> : null}
			<link rel="preconnect" href="https://images.ctfassets.net" />
			<link rel="preconnect" href="https://www.facebook.com" />
			<link rel="preconnect" href="https://connect.facebook.net" />
			<link rel="preconnect" href="https://vars.hotjar.com" />
			<link rel="preconnect" href="https://script.hotjar.com" />
			<link rel="preconnect" href="https://static.hsappstatic.net" />
			<link rel="preconnect" href="https://www.google-analytics.com" />
			<link rel="preconnect" href="https://snap.licdn.com" />
			{cookiesAllowed && <script async src="https://www.googletagmanager.com/gtag/js?id=G-5WT0H2TSDG" />}
			{cookiesAllowed && <script async src="https://www.google-analytics.com/analytics.js" />}
			{cookiesAllowed && (
				<script>
					{`	
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', 'G-5WT0H2TSDG');
						
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-WW98W9N');

						window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
						ga('create', 'UA-54406508-16', 'auto');
						ga('send', 'pageview');

						window = window || {fbAsyncInit: () => null};
						window.fbAsyncInit = function() {
							FB.init({
							  appId            : '621144058854869',
							  autoLogAppEvents : true,
							  xfbml            : true,
							  version          : 'v11.0'
							});
						 };

						gtag('event', 'page_view', {
							'send_to': 'G-5WT0H2TSDG',
							'value': 'replace with value',
							'items': [{
								'id': 'replace with value',
								'google_business_vertical': 'retail'
							}]
						});
						`}
				</script>
			)}
			{cookiesAllowed && (
				<script data-obct type = "text/javascript">
					{`
						/** DO NOT MODIFY THIS CODE**/
						!function(_window, _document) {
						var OB_ADV_ID = '004db25949c3760f64e3b98ad2ad4c2ac8';
						if (_window.obApi) {
							var toArray = function(object) {
								return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
							};
							_window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
							return;
						}
						var api = _window.obApi = function() {
							api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
						};
						api.version = '1.1';
						api.loaded = true;
						api.marketerId = OB_ADV_ID;
						api.queue = [];
						var tag = _document.createElement('script');
						tag.async = true;
						tag.src = '//amplify.outbrain.com/cp/obtp.js';
						tag.type = 'text/javascript';
						var script = _document.getElementsByTagName('script')[0];
						script.parentNode.insertBefore(tag, script);
						}(window, document);
						
						obApi('track', 'PAGE_VIEW');
					`}
				</script>
			)}
			{cookiesAllowed && <script src="https://www.googleoptimize.com/optimize.js?id=GTM-PW2HRG4" />}
			{cookiesAllowed && <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" />}
		</Helmet>
	)
}

export default React.memo(SEO)
