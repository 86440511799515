/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { ThemeProvider } from "@emotion/react"
import Header, { HeaderConfig } from "./Header"
import Footer, { FooterConfig } from "./Footer"
import { theme } from "../common/colorScheme"
import "./layout.css"
import { LinkContext } from "./Link"
import { mediaBreakpoint, BreakPoints } from "../settings/breakpoints"
import { HeaderVariant } from "./Header/types"

const AppContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`

const Main = styled.main`
	flex-grow: 1;

	&.transparent {
		margin-top: -70px;
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		margin-top: 0;
	}
`

interface Props {
	footerConfig?: FooterConfig
	headerConfig?: HeaderConfig
	children: React.ReactNode
	knowledgeCentre?: boolean
	faq?: boolean
}

const Layout: React.FC<Props> = ({ children, footerConfig, headerConfig, knowledgeCentre = false, faq = false }) => {
	const linkContext = React.useMemo(() => {
		return {
			isKnowledgeCentre: knowledgeCentre,
			isFaq: faq,
		}
	}, [knowledgeCentre])

	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)
	const transparent = React.useMemo(() => {
		return (
			headerConfig && [HeaderVariant.TransparentBlack, HeaderVariant.TransparentWhite].includes(headerConfig.variant)
		)
	}, [headerConfig])

	const siteTitle = data.site.siteMetadata.title

	return (
		<LinkContext.Provider value={linkContext}>
			<ThemeProvider theme={theme}>
				<AppContainer>
					<Header siteTitle={siteTitle} config={headerConfig} />
					<Main className={transparent ? "transparent" : ""}>{children}</Main>
					<Footer config={footerConfig} siteTitle={siteTitle} />
				</AppContainer>
			</ThemeProvider>
		</LinkContext.Provider>
	)
}

export default React.memo(Layout)
