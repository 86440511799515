import { css, keyframes, Theme } from "@emotion/react"
import { MediaProps } from "components/Media"

const getColors = (scheme?: IColorScheme) => {
	if (!scheme || !scheme.colors || scheme.colors.length === 0) {
		return ""
	}

	return scheme.colors.join(", ")
}

interface IConfig {
	noImage?: boolean
}

export const getColorScheme = (scheme?: IColorScheme, config?: IConfig) => {
	if (!scheme) {
		return css`
			background: inherit;
		`
	}

	const position = scheme.backgroundImagePosition || "100% 0"
	const size = scheme.backgroundImageSize || "initial"

	const image =
		scheme.backgroundImage && !config?.noImage && `url(${scheme.backgroundImage.file.url}) no-repeat ${position},`

	const useGradient = scheme.colors?.length > 1

	const gradientType = scheme.gradientType ?? "to left"

	return css`
		background: ${image} ${useGradient ? `linear-gradient(${gradientType}, ${getColors(scheme)})` : getColors(scheme)};
		background-size: ${size};
	`
}

export const theme: Theme = {
	spacing: (...args: number[]) => {
		return args.map(a => `${a * 8}px`).join(" ")
	},
	color: {
		white: "#ffffff",
		black: "#000000",
		lightPurple: "#EBE6FF",
		purple: "#9450FF",
		darkPurple: "#340E70",
		teal: "#00C6BC",
		cyan: "#05C1B7",
	},
	animation: {
		breathing: keyframes`
			0% {
				transform: scale(1.1)
			}

			50%{
				transform: scale(0.8)
			}

			100%{
				transform: scale(1.1)
			}
		`,
	},
}

export const getWhiteText = (whiteText?: boolean) => {
	return (
		whiteText &&
		css`
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			span,
			p {
				color: ${theme.color.white};
			}
		`
	)
}

export interface IColorScheme {
	gradientType?: string
	backgroundImage?: MediaProps
	backgroundImagePosition?: string
	backgroundImageSize?: string
	colors: string[]
}
