import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import Image from "gatsby-image"
import { css } from "@emotion/react"
import resizeImageLinkPath, { setPath } from '../../utils/resizeImageLinkPath'

const defaultImageStyles = css`
	max-width: 100%;
	max-height: 100%;
	width: 100%;
`

export interface MediaProps {
	id: string
	file: {
		contentType: string
		url: string
		details: {
			image: {
				width: number
				height: number
			} | null
		}
	} | null
	objectFit?: "cover" | "contain"
	fluid: any | null
	customStyle?: React.CSSProperties
	imgStyle?: any
	title?: string
	description?: string
	alt?: string
	videoRef?: any
	hideControls?: boolean
	loop?: boolean
	gatsbyImageData?: IGatsbyImageData
	width?: number
	poster: any
}

const Media: React.FC<MediaProps> = ({
	fluid,
	customStyle = css``,
	objectFit = "contain",
	file,
	title,
	description: rawDescription,
	alt,
	videoRef,
	hideControls,
	loop = false,
	gatsbyImageData,
	width: resizeWidth,
	...other
}) => {
	const [base64URL, setBase64URL] = React.useState<null | string>(null)
	const isImage = file?.contentType.includes("image")
	const isVideo = file?.contentType.includes("video")

	const processedDescription = rawDescription && rawDescription.replace(/"/g, "")
	const processedTitle = title && title.replace(/"/g, "")
	const processedAlt = alt && alt.replace(/"/g, "")
	const mediaAlt = processedDescription || processedTitle || processedAlt

	React.useEffect(() => {
		if (isVideo) {
			fetch(file.url).then(data => {
				data.arrayBuffer().then(response => {
					const blob = new Blob([response])
					const reader = new FileReader()
					reader.onload = event => {
						if (typeof event.target.result === "string") {
							const base64 = event.target.result.replace("data:", "").replace(/^.+,/, "")
							setBase64URL(`data:video/mp4;base64, ${base64}`)
						}
					}
					reader.readAsDataURL(blob)
				})
			})
		}
	}, [])

	if (gatsbyImageData) {
		return (
			<GatsbyImage
				alt={mediaAlt}
				objectFit={objectFit}
				css={[defaultImageStyles, customStyle]}
				image={resizeImageLinkPath(gatsbyImageData, resizeWidth)}
			/>
		)
	}

	if (fluid) {
		return (
			<Image
				fluid={fluid}
				loading="lazy"
				alt={mediaAlt}
				css={[defaultImageStyles, customStyle]}
				style={{ zIndex: 1 }}
				imgStyle={{
					objectFit,
				}}
				durationFadeIn={100}
				{...other}
			/>
		)
	}

	if (file) {
		if (isImage) {
			const width = file.details?.image.width || 1
			const height = file.details?.image.height || 1

			return (
				<Image
					fluid={{
						sizes: "100w",
						aspectRatio: width / height,
						src: file.url,
						srcSet: file.url,
					}}
					style={{
						maxWidth: "100%",
						width,
						height: "auto",
						zIndex: 1,
					}}
					css={[defaultImageStyles, customStyle]}
					loading="lazy"
					imgStyle={{
						objectFit,
					}}
					alt={mediaAlt}
					durationFadeIn={100}
					{...other}
				/>
			)
		}

		if (isVideo) {
			return (
				// eslint-disable-next-line jsx-a11y/media-has-caption
				<video
					style={{ zIndex: 1, ...customStyle }}
					controls={!hideControls}
					src={base64URL}
					type={file.contentType}
					ref={videoRef}
					loop={loop}
					playsInline
					alt={mediaAlt}
					poster={other.poster ? setPath(other.poster, 1000) : undefined}
					{...other}
				>
					Sorry, your browser doesn&apos;t support embedded videos.
				</video>
			)
		}
	}

	return null
}

export const query = graphql`
	fragment MediaConstrained on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, quality: 95)
	}

	fragment MediaFluid on ContentfulFluid {
		__typename
		src
		sizes
		srcSet
		srcSetWebp
		aspectRatio
	}

	fragment Media800 on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 95)
	}
	fragment Media400 on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 95)
	}
	fragment FeaturedReferenceMedia on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 612, height: 434, quality: 95)
	}
	fragment Media450 on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 450, height: 270, quality: 95, resizingBehavior: THUMB)
	}
	fragment Media600 on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 600, quality: 95)
	}
	fragment MediaOriginal on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: FULL_WIDTH, quality: 95)
	}
	fragment Media160 on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 160, height: 60, quality: 95)
	}
	fragment Media750 on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 700, height: 500, quality: 95)
	}
	fragment HeaderGroupIcon on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 60, height: 60, quality: 95)
	}
	fragment MediaSocialNetworkIcon on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 34, height: 28, quality: 95)
	}
	fragment MediaReviewLogo on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 126, height: 42, quality: 95)
	}
	fragment MediaReviewImage on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 588, height: 588, quality: 95)
	}
	fragment MediaIcon on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			details {
				image {
					width
					height
				}
			}
		}
		gatsbyImageData(layout: CONSTRAINED, width: 48, quality: 95)
	}
`

export default React.memo(Media)
