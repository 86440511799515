import * as React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { ContentfulTypes } from "common/types"
import Link from "components/Link"
import { theme } from "common/colorScheme"
import { HeaderEntry, HeaderEntryLink } from "./types"
import Button, { ButtonProps } from "../button"

interface IMenuIndicatorProps {
	active?: boolean
}

const linkStyles = css`
	position: relative;
	display: inline-block;
	margin: 0 12px;
	font-size: 1.6rem;
	line-height: 1.2;
	text-decoration: none;
	color: inherit;
	text-decoration: none;
	font-weight: 600;
	span {
		color: inherit;
	}
	&:hover {
			color: ${theme.color.purple};
			text-decoration: none;
	}
`

const Wrapper = styled.div`
	position: relative;
	max-height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const buttonStyles = css`
	&:last-child {
		margin-right: 0;
	}
	&[data-variant="hollow"] {
		font-size: 1.6rem;
		font-weight: 600;
		color: var(--text-color);
		> span {
			text-decoration: none;
		}
	}
`

const noLinkStyles = css`
	cursor: default;
`

export const MenuIndicator = styled.div<IMenuIndicatorProps>`
	position: absolute;
	height: 0px;
	width: 100%;
	top: calc(100% + 5px);
	background-color: #fff;
	transform-origin: left;
	transform: ${({ active }) => (active ? "scale(1)" : "scale(0)")};
	transition: transform 0.2s ease-in-out;
	pointer-events: none;
`

interface Props extends HeaderEntry {
	expanded: boolean
}

const Header: React.FC<Props> = ({ link, flyoutLinks, expanded }) => {
	const hasSubMeu = flyoutLinks && flyoutLinks.length
	const LinkComponent = React.useMemo(() => {
		const { __typename } = link

		switch (__typename) {
		case ContentfulTypes.Button: {
			return <Button css={buttonStyles} {...(link as ButtonProps)} />
		}
		default: {
			const { text } = link as HeaderEntryLink
			const { reference } = link as HeaderEntryLink
			const path = reference.slug ? `/${reference.slug}/` : reference.url
			return hasSubMeu ? (
				<div css={[linkStyles, noLinkStyles]}>
					<span>{text}</span>
					<MenuIndicator active={expanded} />
				</div>
			) : (
				<Link css={linkStyles} to={path}>
					<span>{text}</span>
					<MenuIndicator active={expanded} />
				</Link>
			)
		}
		}
	}, [link, expanded, hasSubMeu])

	return <Wrapper aria-expanded={expanded}>{LinkComponent}</Wrapper>
}

export default React.memo(Header)
