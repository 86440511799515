import * as React from 'react'
import Button, { ButtonProps } from 'components/button'
import { HeaderEntry, HeaderEntryLink, ContentfulTypes , ContentfulLinkReference } from 'common/types'

import Link from 'components/Link'
import MobileMenuStyles from './styles'

const {
	buttonCSS,
	linkCSS,
} = MobileMenuStyles

const MobileLink: React.FC<HeaderEntry> = ( { link } ) => {

	const { __typename } = link
	const path = React.useMemo( () => {
		const { reference } = link as HeaderEntryLink
		if ( reference ) {
			return reference.slug ? `/${ reference.slug }/`
				: reference.url
		} 
		return undefined
        
	}, [ link ] )

	switch( __typename ) {
	case ContentfulTypes.Button: {
		return (
			<Button
				css={ buttonCSS }
				{ ...link as ButtonProps }
			/>
		)
	}
	default: {
		const { text } = link as ContentfulLinkReference

		return (
			<Link
				css={ linkCSS }
				to={path}
			>
				<span>
					{text}
				</span>
			</Link>
		)
	}
	}

}

export default React.memo( MobileLink )