import Close from '../../images/icons/close.svg'
import CloseThin from '../../images/icons/close-thin.svg'
import Chevron from '../../images/icons/chevron.svg'
import ArrowLeft from '../../images/icons/arrow-left.svg'
import ArrowRight from '../../images/icons/arrow-right.svg'
import CheckMark from '../../images/icons/check-mark.svg'
import CheckMarkRounded from '../../images/icons/check-mark-rounded.svg'
import ChevronThin from '../../images/icons/chevron-thin.svg'
import BurgerMenu from '../../images/icons/burger-menu.svg'
import Caret from '../../images/icons/caret.svg'
import CustomerStories from '../../images/icons/customer-stories.svg'
import Success from '../../images/icons/success.svg'
import Search from '../../images/icons/search.svg'
import Download from '../../images/icons/download.svg'
import Code from '../../images/icons/code.svg'
import ArrowRightNew from '../../images/icons/Right_Arrow.svg'

export const CloseIcon = Close
export const CloseIconThin = CloseThin
export const ChevronIcon = Chevron
export const ChevronIconThin = ChevronThin
export const ArrowLeftIcon = ArrowLeft
export const ArrowRightIcon = ArrowRight
export const CheckMarkIcon = CheckMark
export const CheckMarkRoundedIcon = CheckMarkRounded
export const BurgerMenuIcon = BurgerMenu
export const CaretIcon = Caret
export const CustomerStoriesIcon = CustomerStories
export const SuccessIcon = Success
export const SearchIcon = Search
export const DownloadIcon = Download
export const CodeIcon = Code
export const ArrowRightNewIcon = ArrowRightNew
