import { ContentfulLinkReference, ContentfulTypes } from "common/types"

const getLinkPath = ( { __typename, slug, url }: ContentfulLinkReference ) => {
	switch( __typename ) {
	case ContentfulTypes.Page:
		return `/${ slug as string }/`
	case ContentfulTypes.LinkUrl:
		return url
	default:
		return '#'
	}
}

export default getLinkPath