import * as React from "react"
import { AnchorProps } from "@reach/router"
import { Link as LinkBase } from "gatsby"
import { GATSBY_FAQ_ENABLED, GATSBY_HOST_PATH, GATSBY_KNOWLEDGE_CENTRE_ENABLED } from "settings/env"
import { css } from "@emotion/react"

interface LinkContextProps {
	isKnowledgeCentre: boolean;
	isFaq: boolean;
}

const linkStyles = css`
	text-decoration: none;
`

export const LinkContext = React.createContext<LinkContextProps>({
	isKnowledgeCentre: false,
	isFaq: false,
})

export const useLinkContext = () => React.useContext(LinkContext)

interface Props extends AnchorProps {
	to: string
	ref?: React.Ref<HTMLAnchorElement>
	children: React.ReactNode
	onClick?: (e: React.MouseEvent) => void
}

// Path to knowledge centre
const KnowledgeCentrePath = "/knowledge-center"

// Path to FAQ page
const FaqPath = "/faq"

const Link: React.FC<Props> = React.forwardRef(({ to, ...props }, ref) => {
	const { isKnowledgeCentre, isFaq } = useLinkContext()

	// Note: knowledge centre is under "knowledge.latana.com" subdomain,
	// for staging it will be under "latanacom.staging.daliaresearch.com/knowledge-center".
	// flag: GATSBY_KNOWLEDGE_CENTRE_ENABLED will be true for production.

	const link = React.useMemo(() => {
		if (GATSBY_KNOWLEDGE_CENTRE_ENABLED && isKnowledgeCentre) {
			if (to.startsWith(KnowledgeCentrePath)) {
				return to.slice(KnowledgeCentrePath.length)
			}
			if (to.startsWith("/")) {
				return `${GATSBY_HOST_PATH}${to}`
			}
		}

		// If it is FAQ domain
		if (GATSBY_FAQ_ENABLED && isFaq) {
			if (to.startsWith(FaqPath)) {
				return to.slice(FaqPath.length)
			}

			if (to.startsWith("/")) {
				return `${GATSBY_HOST_PATH}${to}`
			}
		}

		return to
	}, [to, isKnowledgeCentre])

	const sameOrigin = React.useMemo(() => {
		return link && link.startsWith("/") && !link.startsWith("//")
	}, [link])

	return sameOrigin ? (
		<LinkBase ref={ref} to={link} css={linkStyles} {...props} />
	) : (
		<a ref={ref} href={link} {...props}>
			{props.children}
		</a>
	)
})

export default React.memo(Link)
