import { css } from "@emotion/react"

const buttonCSS = css`
	width: 100%;
	margin: 8px 0;
	&[data-variant="hollow"] {
		font-size: 1.4rem;
		color: var(--text-color);
		> span {
			text-decoration: none;
		}
	}
`

const linkCSS = css`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 6.8rem;
	border: none;
	background: none;
	border-bottom: 1px solid #e9ebed;
	padding: 0 8px;
	text-decoration: none;
	&:hover span {
		color: var(--main-color);
		text-decoration: underline;
	}
	span {
		font-size: 1.6rem;
		line-height: 2.4rem;
		color: var(--text-color);
	}
`

const MobileMenuStyles = {
	buttonCSS,
	linkCSS,
}

export default MobileMenuStyles
