import { IFeatured } from "components/Header/types"
import { MediaProps } from "components/Media"

export interface ContentfulLink {
	id: string
	link: ContentfulLinkReference
	text?: string
	featured?: IFeatured
	description?: {
		description: string
	}
	icon?: MediaProps
}

// a union of a page or a text url
export interface ContentfulLinkReference {
	__typename: ContentfulTypes.LinkUrl | ContentfulTypes.Page | ContentfulTypes.File
	id: string
	slug?: string | null
	text?: string | null
	url?: string | null
	file?: any
}

export interface IJsonContentfulField {
	internal: {
		content: string
	}
}

export enum HeadingAlign {
	Center = "center",
	Left = "left",
	Right = "right",
}

export enum ContentfulTypes {
	Section = "ContentfulLandingSections",
	AlternatingCards = "ContentfulAlternatingCards",
	BrandImages = "ContentfulBrandImagesSection",
	BrandImagesWithReviews = "ContentfulBrandImagesWithReviews",
	Button = "ContentfulButton",
	ProductAddonsSection = "ContentfulProductAddonsSection",
	Card = "ContentfulSectionCards",
	Form = "ContentfulForm",
	Grid = "ContentfulGrid",
	Page = "ContentfulPage",
	LinkUrl = "ContentfulLinksUrl",
	ReferencesSection = "ContentfulReferencesSection",
	BlogPost = "ContentfulBlogPost",
	KnowledgePost = "ContentfulKnowledgePost",
	File = "ContentfulFile",
	Featured = "ContentfulFeaturedSection",
	EmbedSpotifySection = "ContentfulEmbedSpotifySection",
	Timeline = "ContentfulTimeline",
	FaqPost = "ContentfulFaqPost",
    BrandCategorySection = "ContentfulBrandCategorySection"
}
