import * as React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import FooterLink, { FooterLinkProps } from "./FooterLink"
import { SpanH6 } from "../headings"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"

const FooterGroupContainer = styled.div`
	flex: 1;
`

export const FooterGroupLinksContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const groupTitleStyles = css`
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: 800;
	margin: 0;
	color: #181818;
	margin: 0 0 12px;
	${mediaBreakpoint(BreakPoints.SM)} {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`

interface FooterGroupProps {
	title: string;
	links: FooterLinkProps[];
}

const FooterGroup: React.FC<FooterGroupProps> = ({ title, links }) => {
	return (
		<FooterGroupContainer>
			{title ? <SpanH6 css={groupTitleStyles}>{title}</SpanH6> : null}
			<FooterGroupLinksContainer>
				{links.map(link => {
					const { id } = link
					return <FooterLink key={id} {...link} />
				})}
			</FooterGroupLinksContainer>
		</FooterGroupContainer>
	)
}

export const query = graphql`
	fragment FooterGroupLinkPage on ContentfulPage {
		__typename
		id
		slug
	}
	fragment FooterGroupLinkUrl on ContentfulLinksUrl {
		__typename
		id
		url
	}
`

export default React.memo(FooterGroup)
