import React from "react"
import styled from "@emotion/styled"
import { SerializedStyles } from "@emotion/react"
import { mediaBreakpoint, BreakPoints } from "../settings/breakpoints"

interface IContainerProps {
	customStyles?: SerializedStyles
}

const Container = styled.div<IContainerProps>`
	width: 1320px;
	max-width: 100%;
	padding: 0 36px;
	margin: 0 auto;
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: 0 16px;
	}
	${({ customStyles }) => customStyles}
`

export default React.memo(Container)
