import * as React from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import Link from "components/Link"
import { ContentfulLinkReference, IJsonContentfulField } from "common/types"
import * as yup from "yup"
import { animateScroll } from "react-scroll"
import { theme as themeCommon } from "common/colorScheme"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"
import { IThemeValue } from "./sections/section.model"
import { useSectionTheme } from "./sections/SectionThemeContext"

interface IButtonProps {
	sectionTheme?: IThemeValue
}

const getColor = (theme?: IThemeValue) => {
	return theme && theme === "white-text" ? "#fff" : "var(--main-color)"
}

export const ButtonBase = styled.button`
	position: relative;
	appearance: none;
	width: 100%;
	min-width: 136px;
	padding: 0;
	line-height: 1.2;
	border: 1px solid transparent;
	border-radius: 4px;
	background: var(--main-color);
	color: #fff;
	font-size: 1.6rem;
	font-weight: 800;
	cursor: pointer;
	white-space: nowrap;
	transition: 0.15s ease-in-out;
	z-index: 1;
	overflow: hidden;

	${mediaBreakpoint(BreakPoints.SM)} {
		width: initial;
	}

	span {
		transition: 0.15s ease-in-out;
		z-index: 1;
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		width: fit-content;
	}

	&[data-variant="hollow"] {
		white-space: normal;
		min-width: 0px;
		font-size: 1.8rem;
		background: transparent;
		color: ${(props: IButtonProps) => getColor(props.sectionTheme)};
		span {
			border-bottom: 1px solid currentColor;
		}
		&:hover,
		&:focus {
			span {
				border-bottom: 2px solid currentColor;
			}
		}
	}

	&[data-variant="hollow-light-purple"] {
		white-space: normal;
		min-width: 0px;
		font-size: 1.8rem;
		background: transparent;
		color: #9153FE;
		span {
			border-bottom: 1px solid currentColor;
		}
		&:hover,
		&:focus {
			span {
				border-bottom: 2px solid currentColor;
			}
		}
	}

	&[data-variant="hollow-border"] {
		white-space: normal;
		min-width: 0px;
		font-size: 1.8rem;
		background: transparent;
		color: ${(props: IButtonProps) => getColor(props.sectionTheme)};
		border: 2px solid #fff;
		padding: 1.6rem 3.2rem;
	}

	&[data-variant="yellow"],
	&[data-variant="black"],
	&[data-variant="secondary"],
	&[data-variant="secondary-white"],
	&[data-variant="primary"],
	&[data-variant="primary-light"],
	&[data-variant="primary-new"] {
		border-color: var(--main-color);
		padding: 1.6rem 4rem;
	}

	&[data-variant="primary"] {
		&:hover,
		&:focus {
			background: var(--main-color-hover);
		}

		${({ sectionTheme }) =>
		sectionTheme === "white-text"
			? `
            background: #fff;
            color: var( --main-color );
            &:hover, &:focus {
                background: #f4f4f4;
                color: var( --main-color );
            }
        `
			: ""}
	}

	&[data-variant="primary-light"] {
		background: #9450FF;
		border-color: #9450FF;
		border-radius: 21px;
		padding: 9px 15px;
		&:hover,
		&:focus {
			background: var(--main-color-hover);
		}

		${({ sectionTheme }) =>
		sectionTheme === "white-text"
			? `
            background: #fff;
            color: var( --main-color );
            &:hover, &:focus {
                background: #f4f4f4;
                color: var( --main-color );
            }
        `
			: ""}
	}

	&[data-variant="black"] {
		background: black;
		color: white;
		border-color: #000000;
		&:hover,
		&:focus {
			background: var(--main-color-hover);
			color: white;
		}

		${({ sectionTheme }) =>
		sectionTheme === "white-text"
			? `
            background: black;
            color: white;
            &:hover, &:focus {
                background: #f4f4f4;
                color: var( --main-color );
            }
        `
			: ""}
	}

	&[data-variant="yellow"] {
		background: #FF957C;
		color: white;
		border-color: #FF957C;
		&:hover,
		&:focus {
			background:#ffaa96;
			color: white;
		}

	}

	&[data-variant="secondary"] {
		background: transparent;
		color: var(--main-color);

		/* background: linear-gradient(45deg, var( --secondary-dark ), var( --secondary-light ) );
        background-size: 100% 100%;
        background-position: 0% 0%;
        transition: .3s background-position linear, .3s background-size linear; */
		&:hover,
		&:focus {
			background: var(--main-color-hover);
			color: white;
			/* background: linear-gradient( 45deg, var( --secondary-dark ), var( --secondary-light ) );
            background-size: 200% 200%;
            background-position: 100% 100%;
            transition: .3s background-position linear, .3s background-size linear; */
		}
		${({ sectionTheme }) =>
		sectionTheme === "white-text"
			? `
            color: white;
            border-color: white;
            &:hover,&:focus {
                background: var( --main-color-hover );
                color: white;
                border-color: var( --main-color-hover );
            }
        `
			: ""}
	}

	&[data-variant="primary-new"] {
		background: transparent;
		width: 100%;
		border: none;

		& > div {
			display: block;
		}

		&:hover {
			& > div {
				background-position: -70px;
			}
		}

		${mediaBreakpoint(BreakPoints.SM)} {
			width: auto;
		}

		${({ sectionTheme }) =>
		sectionTheme === "white-text"
			? `
            color: ${themeCommon.color.white};
            
        `
			: ""}

		&:hover {
		}
	}

	&[data-variant="secondary-white"] {
		border: 1px solid ${themeCommon.color.white};
		background: transparent;
		color: ${themeCommon.color.white};

		&:hover {
			background-color: ${themeCommon.color.white};
			color: ${themeCommon.color.darkPurple};
		}
	}
`

const GradientFill = styled.div`
	display: none;
	position: absolute;
	height: 100%;
	width: calc(100% + 70px);
	top: 0;
	left: 0;
	background-image: linear-gradient(to right, #e040fb, #00bcd4);
	z-index: -1;
	transition: background 0.5s ease;
`

const schema = yup.object().shape({
	onClick: yup.object().optional().shape({
		scrollTo: yup.string(), // this is an ID to the element it should scroll to
	}),
})

export type IButtonVariants =
"primary" | 
"primary-new" | 
"secondary" | 
"secondary-white" |
"hollow" |
"hollow-border" |
"black" |
"hollow-light-purple" |
"yellow"

export const getPath = (link: ContentfulLinkReference) => {
	if (link) {
		return link.slug ? `/${link.slug}/` : link.url
	}
	return null
}

export interface ButtonProps {
	__typename?: string
	id?: string
	label: string
	newTab?: boolean
	variant: IButtonVariants
	type: "button" | "submit"
	link?: ContentfulLinkReference | null
	customBehavior?: IJsonContentfulField
	bypassTheme?: boolean | null
}

const Button: React.FC<ButtonProps> = ({
	label,
	newTab = false,
	variant,
	type = "button",
	link = null,
	customBehavior,
	bypassTheme = false,
	...other
}) => {
	const sectionTheme = bypassTheme ? "default" : useSectionTheme()
	const path = React.useMemo(() => getPath(link), [link])

	const handleClick = React.useMemo(() => {
		if (customBehavior) {
			try {
				const behavior = schema.validateSync(customBehavior?.internal?.content)

				if (behavior.onClick) {
					return () => {
						// we don't prevent default here because otherwise it would not set the hash from the href
						// and it is used to set the content dynamically in one of the components
						const ref = document.querySelector<HTMLElement>(`#${behavior.onClick.scrollTo}`)
						if (ref) {
							const { top } = ref.getBoundingClientRect()
							// we need an offset to not cover content by the topbar
							const offset = 70
							const destination = top - offset
							animateScroll.scrollTo(destination, {
								duration: 500,
							})
						}
					}
				}
			} catch (err) {
				console.error(err)
			}
		} else if (link && link.url && link.url.startsWith("#")) {
			return (e: React.MouseEvent) => {
				const ref = document.querySelector(link.url)
				if (ref) {
					e.preventDefault()
					ref.scrollIntoView({
						behavior: "smooth",
					})
				}
			}
		}

		return undefined
	}, [link, customBehavior])

	// eslint-disable-next-line no-nested-ternary
	return path ? (
		<Link to={path} onClick={handleClick} target={newTab ? "_blank" : undefined} rel={newTab ? "noopener" : undefined}>
			<ButtonBase sectionTheme={sectionTheme} data-variant={variant} type={type} {...other}>
				{label}
				<GradientFill />
			</ButtonBase>
		</Link>
	) : link?.file.file.url ? (
		<a href={link?.file.file.url} target="_blank" rel="noreferrer" download>
			<ButtonBase sectionTheme={sectionTheme} data-variant={variant} type={type} {...other}>
				{label}
				<GradientFill />
			</ButtonBase>
		</a>
	) : (
		<ButtonBase sectionTheme={sectionTheme} data-variant={variant} type={type} {...other}>
			{label}
			<GradientFill className="gradientFill" />
		</ButtonBase>
	)
}

export const query = graphql`
	fragment Button on ContentfulButton {
		__typename
		id
		label
		variant
		newTab
		bypassTheme
		link {
			...HeaderLinkPage
			...HeaderLinkUrl
			... on ContentfulFile {
				id
				file {
					file {
						url
					}
				}
			}
		}
		customBehavior {
			internal {
				content
			}
		}
	}
`

export default React.memo(Button)
