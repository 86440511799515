import * as React from "react"
import { css } from "@emotion/react"
import Container from "components/container"
import styled from "@emotion/styled"
import { HeaderEntry, HeaderEntryLink } from "components/Header/types"
import { ChevronIcon } from "components/icons"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { ContentfulTypes } from "common/types"
import MenuEntry from "./MenuEntry"

interface ISubMenuWrapperProps {
	expanded: boolean
}

interface IExpandBtnProps extends ISubMenuWrapperProps {}

const BtnText = styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding: 0 0 0 8px;
	flex-grow: 1;
`

const ExpandBtn = styled.button<IExpandBtnProps>`
	cursor: pointer;
	display: flex;
	align-items: center;
	line-height: 6.8rem;
	padding: 0;
	border: none;
	background: none;
	font-size: 1.6rem;
	width: 100%;
	border-bottom: ${({ expanded }) => (expanded ? "none" : "1px solid #e9ebed")};
	justify-content: space-between;
	flex-direction: row;
	&:after {
		content: "";
		display: block;
		width: 10px;
		height: 10px;
		background: url("${ChevronIcon}") no-repeat center center;
		background-size: cover;
		transform: rotate(-90deg);
		transition: transform 0.2s linear;
		align-self: center;
	}
	&:hover {
		${BtnText} {
			color: var(--main-color);
			text-decoration: underline;
		}
	}
`
const SubMenuWrapper = styled.div<ISubMenuWrapperProps>`
	width: 100%;
	background: ${({ expanded }) => (expanded ? "#F8F8F8" : "unset")};
`

const SubMenu = styled.div`
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-in-out;
`

const SubMenuInner = styled.div`
	display: flex;
	flex-direction: column;
	padding: 29px 0px 5px 8px;

	a[href*="#"] {
		opacity: 0.2;
		pointer-events: none;
	}
`

const containerStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 -36px;
	> span {
		font-size: 1.6rem;
		line-height: 2.4rem;
		color: var(--text-color);
	}
	&[aria-expanded="true"] {
		${ExpandBtn}:after {
			transform: rotate(0deg);
			transition: transform 0.2s linear;
		}
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		margin: 0 -16px;
		&[aria-expanded="true"] {
			margin: -1px -16px;
		}
	}
`

const SubMenuEntry: React.FC<HeaderEntry> = ({ link, flyoutLinks, featuredType, featuredBlogPost }) => {
	const { text } = link as HeaderEntryLink
	const subMenuRef = React.useRef<HTMLDivElement>()

	const [expanded, setExpanded] = React.useState(false)
	const [links, setLinks] = React.useState([])

	const displayBlog = featuredBlogPost && featuredType === "blog-post"

	const handleExpand = React.useCallback(() => {
		const subMenu = subMenuRef.current
		if (subMenu) {
			if (expanded) {
				subMenu.style.maxHeight = "0px"
			} else {
				subMenu.style.maxHeight = "1000px"
			}
		}
		setExpanded(!expanded)
	}, [expanded])

	React.useEffect(() => {
		if (flyoutLinks) {
			if (displayBlog) {
				setLinks([
					{
						id: new Date().toISOString(),
						text: "Articles",
						link: {
							__typename: ContentfulTypes.LinkUrl,
							id: new Date().toISOString(),
							slug: "articles",
						},
					},
					...flyoutLinks,
				])
			} else {
				setLinks([...flyoutLinks])
			}
		}
	}, [flyoutLinks, featuredType, featuredBlogPost])

	return (
		<div css={containerStyles} aria-expanded={expanded}>
			<Container>
				<ExpandBtn aria-label="expand" expanded={expanded} onClick={handleExpand}>
					<BtnText>
						<span>{text}</span>
					</BtnText>
				</ExpandBtn>
			</Container>
			<SubMenuWrapper expanded={expanded}>
				<Container>
					<SubMenu ref={subMenuRef}>
						<SubMenuInner>
							{(links || []).map(flyoutLink => {
								const { id } = flyoutLink
								return <MenuEntry key={id} {...flyoutLink} />
							})}
						</SubMenuInner>
					</SubMenu>
				</Container>
			</SubMenuWrapper>
		</div>
	)
}

export default React.memo(SubMenuEntry)
