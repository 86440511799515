export enum BreakPoints {
    XS = 375,
    SM = 576,
    MD = 700, 
    LG = 992,
    XL = 1200,
}

export const mediaBreakpoint = ( breakpoint: BreakPoints ) =>
	`@media screen and (min-width: ${ breakpoint }px)`

export const minWidth = (breakpoint: BreakPoints) => 
	`(min-width: ${breakpoint}px)`