import * as React from "react"
import { css } from "@emotion/react"
import { globalHistory } from "@reach/router"
import { theme } from "common/colorScheme"
import { ButtonBase } from "components/button"
import Container from "../../container"
import Logo from "../../../images/latana-logo.svg"
import { CloseIconThin } from "../../icons"
import MobileSubMenu from "./SubMenu"
import { slideIn, slideOut, SlideTimeout } from "./animations"
import { HeaderEntry } from "../types"
import MobileMenuEntry from "./MobileMenuEntry"

const wrapperStyles = css`
	display: flex;
	flex-direction: column;
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	max-height: 100vh;
	overflow: auto;
	width: 100%;
	z-index: 10;
	animation: ${SlideTimeout}ms ease-in ${slideIn};
	&.closing {
		animation: ${SlideTimeout}ms ease-out ${slideOut} forwards;
		pointer-events: none;
	}

	button[data-variant="primary"] {
		background-color: ${theme.color.darkPurple}!important;
		border: none;
	}

	button[data-variant="hollow"] {
		color: ${theme.color.darkPurple}!important;
	}

	${ButtonBase} {
		width: 100%;
	}
`

const headerStyles = css`
	display: flex;
	border-bottom: 1px solid #e9ebed;
	height: 60px;
	align-items: center;
	position: sticky;
	top: 0;
`

const scrollableContainerStyles = css`
	height: calc(100vh - 60px);
	max-height: calc(100vh - 60px);
	overflow: auto;
`

const headerContainerStyles = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const logoStyles = css`
	height: 40px;
`

const closeBtnStyles = css`
	cursor: pointer;
	display: block;
	background: url("${CloseIconThin}") no-repeat center center;
	background-size: cover;
	border: none;
	width: 32px;
	height: 32px;
`

const menuContainer = css`
	display: flex;
	flex-direction: column;
	align-items: space-between;
	flex-grow: 1;
	justify-content: space-between;
`

const menuTopStyles = css`
	display: flex;
	flex-direction: column;
	margin: 0;
`

const menuBottomStyles = css`
	display: flex;
	flex-direction: column-reverse;
	margin: 24px 0 0;
`

const headerLogoContainer = css`
	display: flex;
	align-items: center;
`

interface Props {
	top: HeaderEntry[]
	bottom: HeaderEntry[]
	extra: HeaderEntry[]
	onClose: () => void
}

const MobileMenu: React.FC<Props> = ({ top, bottom, extra, onClose }) => {
	const wrapperRef = React.useRef<HTMLDivElement>()
	// eslint-disable-next-line no-undef
	const closeTimeout = React.useRef<NodeJS.Timer>()

	const handleClose = React.useCallback(() => {
		clearTimeout(closeTimeout.current)
		if (wrapperRef.current) {
			wrapperRef.current.classList.add("closing")
			closeTimeout.current = setTimeout(() => {
				onClose()
			}, SlideTimeout)
			return () => {
				clearTimeout(closeTimeout.current)
			}
		}
		return undefined
	}, [onClose])

	React.useEffect(() => {
		return globalHistory.listen(() => {
			handleClose()
		})
	}, [handleClose])

	return (
		<div ref={wrapperRef} css={wrapperStyles}>
			<div css={headerStyles}>
				<Container css={headerContainerStyles}>
					<div css={headerLogoContainer}>
						<img css={logoStyles} src={Logo} alt="Latana logo" />
					</div>
					<button type="button" onClick={handleClose} css={closeBtnStyles} aria-label="close menu" />
				</Container>
			</div>
			<div css={scrollableContainerStyles}>
				<Container css={menuContainer}>
					<div css={menuTopStyles}>
						{top.map(entry => {
							const { id, flyoutLinks } = entry
							const hasSubMenu = flyoutLinks && flyoutLinks.length
							return hasSubMenu ? <MobileSubMenu key={id} {...entry} /> : <MobileMenuEntry key={id} {...entry} />
						})}
						{extra.map(entry => {
							const { id } = entry
							return <MobileMenuEntry key={id} {...entry} />
						})}
					</div>
					<div css={menuBottomStyles}>
						{bottom.map(entry => {
							const { id, flyoutLinks } = entry
							const hasSubMenu = flyoutLinks && flyoutLinks.length
							return hasSubMenu ? <MobileSubMenu key={id} {...entry} /> : <MobileMenuEntry key={id} {...entry} />
						})}
					</div>
				</Container>
			</div>
		</div>
	)
}

export default React.memo(MobileMenu)
